<template>
	<div>
		<div class="wrapper">
			<div class="mall-content">
				<div class="mall-left">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane :label="item.title" :name="item.value" v-for="(item, index) in platforms" :key="index">
							<ul>
								<el-skeleton :loading="item.loading" animated :count="10">
									<li class="goods-item" v-for="(goods, i) in item.data" :key="i">
										<goods-item :goods="goods"></goods-item>
									</li>

									<el-empty description="暂无更多数据" v-if="item.data.length <= 0"></el-empty>
								</el-skeleton>
							</ul>
						</el-tab-pane>
					</el-tabs>
				</div>
				<div class="mall-right">
					<el-card class="box-card" shadow="never">
						<div slot="header" class="clearfix">
							<span>专题上新</span>
						</div>
						<el-carousel height="150px">
							<el-carousel-item v-for="(item, index) in banners" :key="index">
								<el-image :src="item"></el-image>
							</el-carousel-item>
						</el-carousel>
					</el-card>

					<el-card class="box-card" shadow="never">
						<div slot="header" class="clearfix">
							<span>2小时热销榜</span>
						</div>
						<div class="material-list">
							<div class="goods-item" v-for="(goods, index) in material_list">
								<simple-goods :goods="goods"></simple-goods>
							</div>
						</div>
					</el-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { Tabs, TabPane, Skeleton, SkeletonItem, Card, Carousel, CarouselItem, Empty } from 'element-ui';
import GoodsItem from '@/views/pc/components/GoodsItem'
import SimpleGoods from '@/views/pc/components/SimpleGoods'

Vue.use(Tabs).use(TabPane).use(Skeleton).use(SkeletonItem).use(Card).use(Carousel).use(CarouselItem).use(Empty)

import { getRefinement, getMaterialList } from '@/api/pc'

export default {
	name: "PcHome",
	components: {
		GoodsItem, SimpleGoods
	},
	data () {
		return {
			activeName: 'tb',
			platforms: {
				tb: {
					title: "淘 宝",
					loading: true,
					value: 'tb',
					data: []
				},
				jd: {
					title: "京 东",
					loading: true,
					value: 'jd',
					data: []
				},
				pdd: {
					title: "拼多多",
					loading: true,
					value: 'pdd',
					data: []
				},
				vip: {
					title: "唯品会",
					loading: true,
					value: 'vip',
					data: []
				}
			},
			material_list: [],
			banners: [
				"http://img.taojiefang.cn/FqgScSyB2x3rFc9hIE_5rUP-yM5a",
				"http://img.taojiefang.cn/FlbGFKJjn-lzs501UQdyUDEqWMk0",
				"http://img.taojiefang.cn/Fi-IvDF3Ni5fSEi33eC_uYuyDVGr",
				"http://img.taojiefang.cn/FkvYZv3vvb4QwojlFTX2ciNi2Vpj"
			]
		}
	},
	computed: {},
	created () {},
	mounted () {
		this._loadRefinement(this.activeName)
		this._loadMaterialList()
	},
	methods: {
		handleClick(val) {
			this._loadRefinement(this.activeName)
		},
		_loadRefinement(platform) {

			if(this.platforms[this.activeName].data.length>0) {
				return;
			}

			getRefinement({
				toPage: 1,
				rows: 20,
				platform: platform
			}).then(res => {
				if(res.errCode === 0) {
					this.platforms[this.activeName].loading = false
					this.platforms[this.activeName].data = res.data
				}
			})
		},
		_loadMaterialList() {
			getMaterialList({
				material_id: "28026",
				rows: 10
			}).then(res => {
				if(res.errCode === 0) {
					this.material_list = res.data
				}
			})
		}
	}
}
</script>

<style scoped>
.mall-content {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}

.mall-left {
	box-sizing: border-box;
	padding: 10px;
	width: 600px;
	min-height: 600px;
	background: #fff;
}

.mall-right {
	box-sizing: border-box;
	width: 390px;
	min-height: 600px;
}

.goods-item {
	border-bottom: 1px solid #f8f8f8;
}
.goods-item:last-child {
	border-bottom: none;
}
.box-card {
	margin-bottom: 10px;
}
</style>
