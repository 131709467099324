<template>
  <div class="goods-item" @click="_goto">
    <div class="goods-image">
      <el-image :src="goods.pict_url" lazy></el-image>
    </div>
    <div class="goods-content">
      <div class="goods-title">{{ goods.goods_title }}</div>
      <div class="goods-extra">
        <Coupon :couponMoney="goods.coupon_amount" />
				<div class="goods-price">
					<span class="price-text">券后价：</span>
					<span class="price">¥ {{ goods.price }}</span>
				</div>
				<div class="goods-tools">
					<div class="tools-left">
						<span class="like"><img src="@/assets/pc_images/like.png" alt=""></span>
						<span class="like-text">100%</span>
						<span class="volume" v-if="goods.volume > 100">月销量：{{ goods.volume }}</span>
					</div>
					<div class="tools-right">
						<span class="platform">{{ goods.platform_cn?goods.platform_cn:goods.platform }}</span>
						<span class="buy-btn">查看详情</span>
					</div>
				</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Coupon from '@/views/pc/components/Coupon'
import { Image } from 'element-ui';

Vue.use(Image)

export default {
	props: {
		goods: {
			type: Object,
			default: function() {
				return {}
			}
		}
	},
  components: {
		Coupon
	},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
		_goto() {
			if(!this.goods) {
				return;
			}

			this.$router.push({name: 'GoodsItem', query: {id: this.goods.goods_id}})
		}
	},
};
</script>

<style scoped lang="scss">
@import "~@/css/variables.scss";

.goods-item {
	display: flex;
	padding: 10px 0;
	cursor: pointer;
}

.goods-image {
	width: 150px;
	height: 150px;
	border-radius: 8px;
	overflow: hidden;
	font-size: 0;
	margin-right: 20px;
}

.goods-content {
	flex: 1;
}

.goods-title {
	height: 50px;
	overflow: hidden;
	line-height: 25px;
	font-size: 14px;
	color: #333;
}

.goods-price {
	padding: 10px 0;
	font-size: 16px;
	color: $--price-color;
	font-weight: 600;
}

.goods-tools {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.tools-left {
	display: flex;
	align-items: center;
}
.like {
	width: 15px;
	height: 15px;
	font-size: 0;
	margin-right: 7px;
}
.like img {
	width: 100%;
}
.like-text {
	font-size: 12px;
	color: #888;
	margin-right: 10px;
}
.volume {
	font-size: 13px;
	color: #555;
}

.tools-right {
	display: flex;
	align-items: center;
}

.platform {
	padding: 3px 8px;
	background: #302516;
	color: #e5cb97;
	font-size: 12px;
	border-radius: 3px;
	margin-right: 20px;
}

.buy-btn {
	width: 100px;
	height: 30px;
	border-radius: 5px;
	background: linear-gradient(to left, $--color-primary, $--price-color);
	line-height: 30px;
	color: #fff;
	font-weight: 500;
	font-size: 16px;
	text-align: center;
}
</style>
